<!--  -->
<template>
   <div class="foot">
    <footer>
      <div class="future">
        <img src="@/assets/home/future.png" alt="">
      </div>
      <div class="info">
        <div class="top-logo">
          <a href="#">
            <img alt="小瑜科技" src="@/assets/logo-footer.png">
          </a>
        </div>
        <p class="foot-info">
          成都七号科技有限公司（Chengdu ShawYo Technology Co.,Ltd.）</br>
          地址：成都市高新区天府五街美年广场D座12层</br>
          电话：18080081007 / 028-66110006</br>
          <a href="https://beian.miit.gov.cn/" target="_blank">备案号：蜀ICP备2020036819号-1</a></br>
        </p>
      </div>
      </footer>
  </div>
  

</template>

<script>
export default {
  data () {
    return {
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {}
}

</script>
<style lang='scss' scoped>

.foot {
    height: 350px;
    background: #000000;
    padding-top: 101px;
  
    .future,
    .info {
      float: left;
    }
  
    .future {
      margin-right: 61px;
    }
  
    footer {
      width: 1440px;
      padding: 15px;
  margin: 0 auto;
      .top-logo {
        margin-bottom: 24px;
      }
  
      .foot-info {
        font-size: 13px;
        line-height: 24px;
      }
    }
  }
</style>