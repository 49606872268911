import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/home',
    component: Layout,
    children:[
      {
        path: 'home',
        name: 'Home',
        component: ()=> import('@/views/edipdemic/index'),
        meta:{
          title:'疫情防控·链路管理系统，社区防疫软件，疫情防控闭环软件，新型肺炎防控软件'
        }
      }
    ]
  },
  {
    path: '/softwareCustom',
    redirect:'/softwareCustom/index',
    component: Layout,
    children:[
      {
        path: 'index',
        name: 'SoftwareCustom',
        component: ()=> import('@/views/softwareCustom/index.vue'),
        meta:{
          title:'成都软件定制开发，成都APP开发，成都微信小程序开发公司'
        }
      }
    ]
  },
  {
    path: '/yuanqu',
    redirect:'/yuanqu/index',
    component: Layout,
    children:[
      {
        path: 'index',
        name:'YuanQu',
        component: ()=> import('@/views/home/index.vue'),
        meta:{
          title:'智慧园区系统'
        }
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
