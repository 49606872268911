<!--  -->
<template>
  <!-- <nav class="navbar navbar-default navbar-static-top">
        <div class="container-fluid">
          <div class="navbar-header">
            <a class="navbar-brand" href="#">
              <img alt="七号科技" src="@/assets/logo.png">
            </a>
            
          </div>
          
          <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav navbar-right">
              <li><a href="#">智慧园区系统</a></li>
              <li><a href="#">智慧机房系统</a></li>
              <li><a href="./softwareCustom.html">软件定制开发</a></li>
              <li><a href="#">关于我们</a></li>
              <li><a href="#"><i class="icon phone"></i>028-66110006</a></li>
            </ul>
            </div>
        </div>
      </nav> -->

  <header class="my-header my-container">
    <div class="my-header-content">
      <div class="logo"></div>

      <ul class="my-nav">
        <li
          class="nav-item"
          :class="{ 'active-nav': currentName == item.routeName }"
          v-for="item in menuList"
          :key="item.routeName"
        >
          <router-link :to="{name:item.routeName}">
            <div class="nav-title">{{ item.title }}</div>
          </router-link>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { check } from "@/utils/utils.js";
const menuList = [
{
    title: "疫情链路管理",
    routeName: "Home",
  },
  {
    title:'智慧园区系统',
    routeName: "YuanQu",
  },
  
  {
    title: "软件定制开发",
    routeName: "SoftwareCustom",
  },
  {
    title: "028-66110006 / 18080081007",
    routeName: "",
  },
];
export default {
  data() {
    return {
      menuList,
    };
  },

  components: {},

  computed: {
    currentName() {
      return this.$route.name;
    },
    goHome() {},
  },

  mounted() {
    // 点击导航菜单
   
  },

  methods: {},
};
</script>
<style lang='scss' scoped>
.my-header {
  width: 100%;
  height: 106px;
  line-height: 106px;
  background: #0c0f15;
  position: absolute;
  top: 0;
  z-index: 11111;
  opacity: 0.8;
  // box-shadow: 0px 5px 9px 0px rgba(192, 192, 192, 0.49);
  .my-header-content {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    .logo {
      background: url("~@/assets/logo.png");
      cursor: pointer;
      background-size: 342px 74px;
      width: 342px;
      height: 74px;
      margin-right: auto;
    }

    .my-nav {
      display: flex;
      justify-content: space-around;
      margin-right: 70px;
      .nav-item {
        div {
          padding: 0 31px;
          color: #fff;
          font-size: 18px;
        }
      }
      .active-nav,
      .nav-item:hover {
        background: #2E4892;
        // color: ;
      }
    }
  }
}



</style>