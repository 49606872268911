export function check(element, cn) {
    //切换类 如果有类中有box_add就删除 没有添加
    let reg = new RegExp("\\b" + cn + "\\b");
    if (reg.test(element.className)) {
      element.className = element.className.replace(reg, "");
    } else {
      element.className = element.className + " " + cn;
    }
  }

  // 判断是否是手机端
export function _isMobile () {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}