<!--  -->
<template>
  <div>
    <head-com></head-com>
    <main>
        <router-view></router-view>
    </main>
    <foot-com></foot-com>
  </div>
</template>

<script>
import FootCom from './components/FootCom.vue'
import HeadCom from './components/HeadCom.vue'
export default {
  data () {
    return {
    };
  },

  components: {
    FootCom,
    HeadCom
  },

  computed: {},

  mounted() {},

  methods: {}
}

</script>
<style lang='scss' scoped>

</style>