<template>
  <div id="app">
    
    <router-view/>
  </div>
</template>
<script>
import {_isMobile} from '@/utils/utils.js'
export default {
  created() {
    if(_isMobile()){
      location.href="https://m.seventc.com"
    }
   
  },
}
</script>
<style lang="scss">

</style>
